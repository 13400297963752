import { DefaultEventsMap, Socket } from 'socket.io';

export type AuthEvents = {
  authorize: () => Promise<unknown>;
  logout: () => Promise<unknown>;
};

export type StatisticsEvents = {
  getOnline: () => Promise<unknown>;
};

export type NotificationEvents = {
  getNotifications: (value?: {
    limit: number; // Default: 10, min: 1, max: 20
    lastId: number; // min: 0
  }) => Promise<unknown>;
  missedNotifications: (value: {
    limit: number; // Default: 50, min: 1, max: 50
    maxId: number; // min: 0 // ❗️ Если список уведомлений уже существует на сайте
    // при подключении после потери соединения с сокетом, необходимо запросить пропущенные уведомления,
    // укажите в maxId самый первый большой id уведомления.) => Promise<unknown>;
  }) => Promise<unknown>;
  readNotification: (value?: {
    notificationId: string; // "13" Опционально: если не указывать, отметит все уведомления как прочитанные
  }) => Promise<unknown>;
};

export type SocketEventsMap = {
  auth: ({
    socket,
  }: {
    socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  }) => AuthEvents;

  notifications: ({
    socket,
  }: {
    socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  }) => NotificationEvents;

  statistics: ({
    socket,
  }: {
    socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  }) => StatisticsEvents;
};

export const notificationSocketEvents: SocketEventsMap['notifications'] = ({
  socket,
}: {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}) => {
  return {
    getNotifications: args =>
      defaultSocketPromise({
        socket,
        eventName: 'getNotifications',
        errorMessage: 'Error fetch notifications',
        args,
      }),
    missedNotifications: args =>
      defaultSocketPromise({
        socket,
        eventName: 'missedNotifications',
        errorMessage: 'Error fetching missed notifications',
        args,
      }),
    readNotification: args =>
      defaultSocketPromise({
        socket,
        eventName: 'readNotification',
        errorMessage: 'Error read notifications',
        args,
      }),
  };
};

export const authSocketEvents: SocketEventsMap['auth'] = ({
  socket,
}: {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}) => {
  return {
    authorize: () =>
      defaultSocketPromise({
        socket,
        eventName: 'authorize',
        errorMessage: 'Error authorize',
      }),
    logout: () =>
      defaultSocketPromise({
        socket,
        eventName: 'logout',
        errorMessage: 'Error logout',
      }),
  };
};

export const statisticsSocketEvents: SocketEventsMap['statistics'] = ({
  socket,
}: {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}) => {
  return {
    getOnline: () =>
      defaultSocketPromise({
        socket,
        eventName: 'getOnline',
        errorMessage: 'Error get online statistics',
      }),
  };
};

export const defaultSocketPromise = async ({
  socket,
  eventName,
  args,
  errorMessage,
}: {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  eventName: string;
  errorMessage?: string;
  args?: any;
}) => {
  try {
    return new Promise((resolve: (value: unknown) => void, reject) => {
      socket.emit(
        eventName,
        { ...args },
        (response: void | PromiseLike<void>, msg: any) => {
          const answer = {
            response,
            msg,
          };

          return resolve(answer);
        },
      );
    });
  } catch (error) {
    console.error(errorMessage ? errorMessage : 'Error fetching:', error);
  }
};
