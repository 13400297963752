'use client';
import { RefObject, useEffect, useMemo, useState } from 'react';
export const useIsInViewport = (ref: RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  let main: HTMLElement | null = null;

  if (typeof window !== 'undefined') {
    main = document.body.querySelector('main');
  }
  const options = {
    root: main,
    threshold: 0,
  };

  const observer = useMemo(
    () =>
      main &&
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        options,
      ),
    [options, main],
  );

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);
    }

    return () => {
      observer && observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};
