import { uuid } from 'uuidv4';

export const useBrowserId = () => {
  let browserId =
    typeof window !== 'undefined' ? localStorage.getItem('browserId') : null;
  if (!browserId) {
    browserId = uuid();
    typeof window !== 'undefined' &&
      localStorage.setItem('browserId', browserId);
  }

  const handleClearBrowserId = () => {
    localStorage.removeItem('browserId');
  };

  return { browserId, handleClearBrowserId };
};
