'use client';
import { useState } from 'react';

export const useDropList = () => {
  const [dropListOpened, setDropListOpened] = useState(false);

  const toggleDropDown = () => {
    setDropListOpened(!dropListOpened);
  };

  return {
    toggleDropDown,
    dropListOpened,
    setDropListOpened,
  };
};
