'use client';

import { useBrowserId } from '@hooks/client';
import { getCookie } from 'cookies-next';
import { io } from 'socket.io-client';

const token = getCookie('jwt') ?? 'empty';

// eslint-disable-next-line react-hooks/rules-of-hooks
const { browserId } = useBrowserId();
export const notificationsSocket = io(
  process.env.NEXT_PUBLIC_NOTIFICATIONS_BASE_URL,
  {
    transports: ['websocket'],
    path: '/connection/',
    protocols: [token, browserId],
    withCredentials: true,
  },
);
