'use client';
import { useEffect, useState } from 'react';
import { useCountdown } from 'usehooks-ts';

type NumberDaysRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type DayString = `${NumberDaysRange}d`;

export type Timer = {
  duration: number | DayString;
  asCountdown?: boolean;
  device?: 'mobile' | 'desktop';
};

export const sec2time = (
  timeInSeconds: number,
  device?: 'desktop' | 'mobile',
) => {
  const pad = (num: number, size: number): string => {
    return ('000' + num).slice(size * -1);
  };
  const time = parseFloat(timeInSeconds.toFixed(3));
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const days = Math.floor(time / 60 / 60 / 24);

  return days > 0
    ? device === 'desktop'
      ? `${days} days`
      : `${days}d`
    : `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

export const useTimer = ({ duration, asCountdown = true, device }: Timer) => {
  const [intervalValue] = useState<number>(1000);

  const durationInSec =
    typeof duration !== 'number' ? parseInt(duration) * 86400 : duration;

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: durationInSec,
      intervalMs: intervalValue,
      isIncrement: !asCountdown,
    });

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    resetCountdown();
    startCountdown();
  }, [duration]);

  return {
    timer: sec2time(count, device),
    count,
    stopCountdown,
    resetCountdown,
    startCountdown,
  };
};
